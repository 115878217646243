import React from "react";
import "./App.css";
import { Link } from "react-router-dom";
import Typewriter from "typewriter-effect";
// import BG_IMAGE from "./assets/rishi.png";
import BG_IMAGE from "./assets/rishi_img.webp";
import HEROIMG from "./assets/hero_logo.png";
// import CLOSE from "./assets/close.png";
import CLOSE from "./assets/menu_burger.png";
import FB from "./assets/facebook.png";
import TWITTER from "./assets/twitter.png";
import INSTAGRAM from "./assets/instagram.png";
import LINKEDIN from "./assets/linkedin.png";
import YOUTUBE from "./assets/youtube.png";
import DOWN_ARROW from "./assets/down-arrow.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { base_url, signup_endpoint } from "./config";
// import ReactPlayer from 'react-player'

class MainLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headerDynamicClass: "bg-transparent",
      header2DynamicClass: "",
      scrollPosition: 0,
      first_name: null,
      last_name: null,
      email: null,
      mobile: null,
      postcode: null,
      termAndCondition: null,
      partyMember: null,
      privarcyPolicy: false,
      afterSumitForm: false,
      shareModal: false,
      errorText: null,
      responsiveHeader: false,
      youtubeVideos: []
    };

  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    window.scrollTo(0, 0);
    this.setYoutubeVideosLinks()
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  setYoutubeVideosLinks = () => {
    let data = [
      { id: 1, title: 'v1', width: "300", height: "200px", src: 'https://www.youtube.com/embed/Qxajk5N3dkY' },
      { id: 2, title: 'v2', width: "300", height: "200px", src: 'https://www.youtube.com/embed/WbGWLgznRP8' },
      { id: 3, title: 'v3', width: "300", height: "200px", src: 'https://www.youtube.com/embed/GA7Kt3uU5II' },
    ]


    this.setState({ youtubeVideos: data })
  }

  handleScroll = () => {
    const position = window.pageYOffset;
    this.setState({ scrollPosition: position });
    if (position > 750) {
      this.setState({ header2DynamicClass: "bg-color-3" });
    } else {
      this.setState({ header2DynamicClass: "" });
    }
  };

  submitForm = (e) => {
    e.preventDefault();
    console.log("Clicked", this.state);
    //   this.setState({errorText:null});
    const {
      first_name,
      last_name,
      email,
      mobile,
      postcode,
      partyMember,
      termAndCondition,
    } = this.state;
    this.setState({ errorText: null });
    //checking for the null value
    if (!first_name) {
      return this.setState({
        errorText: { first_name: "Please enter First name" },
      });
    }
    if (!last_name) {
      return this.setState({
        errorText: { last_name: "Please enter Last name" },
      });
    }
    if (!mobile) {
      return this.setState({
        errorText: { mobile: "Please enter Mobile number" },
      });
    }
    if (!email) {
      return this.setState({ errorText: { email: "Please enter Email" } });
    }
    if (!postcode) {
      return this.setState({
        errorText: { postcode: "Please enter Postcode" },
      });
    }
    if (!partyMember) {
      return this.setState({
        errorText: {
          partyMember: "Please select  conservative party member option",
        },
      });
    }
    if (!termAndCondition) {
      return this.setState({
        errorText: { termAndCondition: "Please accept Terms and Conditions" },
      });
    }

    if (mobile) {
      if (mobile.length < 10) {
        return this.setState({
          errorText: { mobile: " Invalid Mobile number" },
        });
      }
    }
    console.log(this.state.errorText);

    this.submitApiCall();
  };

  submitApiCall() {
    let reqPayload = {
      first_name: this.state?.first_name,
      last_name: this.state?.last_name,
      phone_number: this.state?.mobile,
      email: this.state?.email,
      postcode: this.state?.postcode,
      member_of_conservative_party:
        this.state?.partyMember === "Yes" ? true : false,
    };
    console.log(this.state, reqPayload);
    let url = base_url + signup_endpoint;
    let fetchOptions = {
      method: "post",
      headers: { "Content-Type": "application/json", token: 12345 },
      body: JSON.stringify(reqPayload),
    };
    fetch(url, fetchOptions)
      .then((res) => res.json())
      .then((result) => {
        if (result?.errors) {
          toast.dark(result?.errors, {
            position: "bottom-center",
            autoClose: 5000,
            type: "error",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (result?.data) {
          localStorage.setItem("id", result?.data.id);
          this.props.history("/submit");
        }
      });
  }
  partyMemberSelection = (isChecked, value) => {
    //   console.log(isChecked,value);
    this.setState({ partyMember: value });
  };

  termConditionCheck = (isChecked, value) => {
    console.log(isChecked, value);
    if (isChecked) {
      this.setState({ termAndCondition: value });
    } else {
      this.setState({ termAndCondition: null });
    }
  };




  render() {

    // console.log(this.props)
    return (
      <div className="app-main">
        {/* <SideBar /> */}
        <ToastContainer />
        {this.state.responsiveHeader &&
          <div className="float-feeds-wrapper">
            <div className="float-feeds-container">

              <div className="flex_sb_center">

                <div className="sidebar_head">
                  Social Media Feeds
                </div>


                <span className="float-close-button" onClick={(e) => {

                  this.setState({ responsiveHeader: false })
                  document.documentElement.style.overflow = 'unset';  // firefox, chrome
                  document.body.scroll = "yes"; // ie only
                }
                } >
                  <img src={CLOSE} height="20px" width="20px" alt="" />

                </span>

              </div>

              <div className="feed-buttons">
                <div className="topnav-btn twitter" onClick={() => { this.props.history('/twitter-feeds') }}>
                  <img src={TWITTER} height="20px" width="20px" alt="Twitter" />
                  Twitter Feed
                </div>
                <div className="topnav-btn linkedin" onClick={() => {
                  // this.props.history('/linkedin-feeds')
                  window.open("https://uk.linkedin.com/in/rishi-sunak-4361871a5")

                }}>
                  <img src={LINKEDIN} height="20px" width="20px" alt="Linkedin" />
                  Linkedin Feed</div>
              </div>

            </div>
          </div>
        }
        <div className="float-feeds">
          <div className="float-feeds-button linkedin">
            <div className="feed-flex" onClick={() => {
              // this.props.history('/linkedin-feeds')
              window.open("https://uk.linkedin.com/in/rishi-sunak-4361871a5")
            }}>
              <img style={{ marginTop: 4 }} src={LINKEDIN} height="20px" width="20px" alt="social-handel" />
              <span className="float-feeds-txt">Feed</span>
            </div>

          </div>
          <div className="float-feeds-button twitter">
            <div className="feed-flex" onClick={() => { this.props.history('/twitter-feeds') }}>
              <img src={TWITTER} height="30px" width="30px" alt="social-handel" />
              <span className="float-feeds-txt">Feed</span>
            </div>

          </div>
        </div>



        <section id="hero" className="hero" aria-label="Hero section">

          <header
            id="header"
            className={`navbar navbar-expand-md navbar-dark position-absolute p-4 text-light bg-color-3 ${this.state?.headerDynamicClass}`}
          >
            <div className="mx-auto container-fluid d-flex  row" >

              <div className="col-md-1"></div>
              <div
                className="navbar-brand-container flex-grow-1 py-2 px-1 px-md-3 p-md-0"
                style={{ alignSelf: "self-start" }}
              >
                <input id="menu-toggle" type="checkbox" onChange={(e) => {
                  this.setState({ responsiveHeader: !this.state.responsiveHeader })
                  document.documentElement.style.overflow = 'hidden';  // firefox, chrome
                  document.body.scroll = "no"; // ie only
                }} />
                <label className='menu-button-container' htmlFor="menu-toggle">
                  <div className='menu-button'></div>
                </label>


                {/* <div className=" navbar-brand-container flex-grow-1 py-2 px-1 px-md-3 p-md-0 text-center"> */}
                <h1 className="h3 mb-0 logo-center-responsive">
                  <a className="navbar-brand text-color-2" href="/">
                    <img
                      // src="https://assets.nationbuilder.com/themes/62c6bf4fc294806c46e9a325/attachments/original/1657202390/rishi_logo.png?1657202390"
                      src={HEROIMG}
                      className="mx-auto navbar-brand-image"
                      alt="Ready for Rishi"
                    />
                  </a>
                </h1>
              </div>
              <nav
                className="col-md-4 px-0 collapse navbar-collapse position-fixed nav-class"
                id="primaryNav"
                aria-label="Main navigation"
              >
                <ul
                  className={`navbar-nav flex-wrap ml-auto icon-custom ${this.state?.header2DynamicClass}`}
                  style={{ flexDirection: "column" }}
                >
                  <li className="nav-item">
                    <a
                      href="https://www.facebook.com/rishisunak"
                      className="nav-link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={FB} alt="not_found" className="social-icon" />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="https://twitter.com/RishiSunak"
                      className="nav-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={TWITTER}
                        alt="not_found"
                        className="social-icon"
                      />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="https://www.instagram.com/rishisunakmp/"
                      className="nav-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={INSTAGRAM}
                        alt="not_found"
                        className="social-icon"
                      />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="https://www.youtube.com/c/RishiSunakMP"
                      className="nav-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={YOUTUBE}
                        alt="not_found"
                        className="social-icon"
                      />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="https://www.linkedin.com/in/rishi-sunak-4361871a5/"
                      className="nav-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={LINKEDIN}
                        alt="not_found"
                        className="social-icon"
                      />
                    </a>
                  </li>
                </ul>
                <ul
                  id="navbar-toolbar"
                  className="navbar-toolbar list-unstyled d-flex align-items-center mt-2 mt-md-0 mb-2 mb-md-0 ml-3"
                ></ul>
              </nav>
            </div>
          </header>

          <div
            className="hero-container hero-homepage bg-color-3 d-flex align-items-center jumbotron jumbotron-fluid h-100  position-relative header-custom"
            style={{
              backgroundImage: 'url("' + BG_IMAGE + '")',
              marginBottom: 0
            }}
          >
            <div className="position-absolute"></div>
            <div className="scroll-view">
              <div className="arrow-animation"
                onClick={() => {
                  document.getElementById("mobile_form").scrollIntoView({ behavior: 'smooth' });
                }}
              >  <img src={DOWN_ARROW} height="50px" width="50px" alt="Down" /></div>
              <p
                style={{ fontWeight: 500 }}
              >Scroll down for joining our campaign</p>
            </div>
            <div className="container web_container">
              <div id="main-content" className="hero-page-content mt-4">
                <div className="row">
                  <div className="col-12">
                    <h1
                      style={{ lineHeight: 1 }}
                      className="display-4 text-uppercase"
                    >
                      Ready to
                      {/* <br /> */}
                      {/* <span
                                  data-twreplace-charspeed="45"
                                  data-twreplace-wordspeed="2000"
                                  data-twreplace='["Rebuild.","Reunite.","Restore Trust."]'
                                >
                                  Reunite.
                                </span> */}
                      <div>
                        <Typewriter
                          onInit={(typewriter) => {
                            typewriter
                              .typeString("Rebuild.")
                              .pauseFor(1000)
                              .deleteChars(7)
                              .typeString("eunite.")
                              .pauseFor(1000)
                              .deleteChars(7)
                              .typeString("estore Trust.")
                              .start()

                          }}
                          options={{
                            loop: true,
                          }}
                        />
                      </div>
                      &nbsp;
                    </h1>
                  </div>
                  <div className="col-lg-6 d-none d-md-block">
                    <h5
                    //   style={{width: '570.5px'}}
                    >
                      JOIN OUR CAMPAIGN FOR THE LATEST INFORMATION AND UPDATES
                    </h5>
                    <div className="form">
                      <form
                        id="join_page_new_signup_form"
                        className="ajaxForm signup_form"
                        onSubmit={(e) => {
                          this.submitForm(e);
                        }}
                        method="POST"
                      // action="/forms/signups"
                      // encType="multipart/form-data"
                      >
                        <input
                          name="authenticity_token"
                          type="hidden"
                          value="ZCFulLvDVhsuuB0wx7gyij9sHSWjsxFvC2lrw3xc9vM="
                        />
                        <input name="page_id" type="hidden" value="9" />
                        <input
                          name="return_to"
                          type="hidden"
                          value="https://www.ready4rishi.com/"
                        />
                        <div
                          className="email_address_form"
                          style={{ display: "none" }}
                          aria-hidden="true"
                        >
                          <p>
                            <label htmlFor="email_address_9">
                              Optional email code
                            </label>
                            <br />
                            <input
                              name="email_address"
                              type="text"
                              className="text"
                              id="email_address_9"
                              autoComplete="off"
                            />
                          </p>
                        </div>
                        <div className="form-errors"></div>
                        <div className="form-row">
                          <div className="form-group col-sm-6">
                            <div className="form-control-material">
                              <label
                                htmlFor="signup_first_name_9"
                                className="floating-label"
                              >
                                First Name*
                              </label>
                              <input
                                required="required"
                                className="form-control text"
                                id="signup_first_name_9"
                                name="signup[first_name]"
                                type="text"
                                onChange={(event) => {
                                  this.setState({
                                    first_name: event.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {this.state?.errorText?.first_name ? (
                            <p className="error-block">
                              {this.state?.errorText?.first_name}
                            </p>
                          ) : null}
                          <div className="form-group col-sm-6">
                            <div className="form-control-material">
                              <label
                                htmlFor="signup_last_name_9"
                                className="floating-label"
                              >
                                Last Name*
                              </label>
                              <input
                                required="required"
                                className="form-control text"
                                id="signup_last_name_9"
                                name="signup[last_name]"
                                type="text"
                                onChange={(event) => {
                                  this.setState({
                                    last_name: event.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {this.state?.errorText?.last_name ? (
                            <p className="error-block">
                              {this.state?.errorText?.last_name}
                            </p>
                          ) : null}
                        </div>
                        <div className="form-row">
                          <div className="form-group col-sm-6">
                            <div className="form-control-material">
                              <label
                                htmlFor="signup_email_9"
                                className="floating-label"
                              >
                                Email*
                              </label>
                              <input
                                required="required"
                                className="form-control text"
                                id="signup_email_9"
                                name="signup[email]"
                                type="email"
                                onChange={(event) => {
                                  this.setState({ email: event.target.value });
                                }}
                              />
                            </div>
                          </div>
                          {this.state?.errorText?.email ? (
                            <p className="error-block">
                              {this.state?.errorText?.email}
                            </p>
                          ) : null}

                          <div className="form-group col-sm-6">
                            <div className="form-control-material">
                              <label
                                htmlFor="signup_mobile_number_9"
                                className="floating-label"
                              >
                                Mobile phone*
                              </label>
                              <input
                                required="required"
                                className="form-control text"
                                id="signup_mobile_number_9"
                                name="signup[mobile_number]"
                                type="tel"
                                onChange={(event) => {
                                  this.setState({ mobile: event.target.value });
                                }}
                              />
                            </div>
                          </div>
                          {this.state?.errorText?.mobile ? (
                            <p className="error-block">
                              {this.state?.errorText?.mobile}
                            </p>
                          ) : null}
                        </div>
                        <div className="form-group ">
                          <div className="form-control-material">
                            <label
                              htmlFor="signup_home_address_zip_9"
                              className="floating-label"
                            >
                              Postcode*
                            </label>
                            <input
                              required="required"
                              className="form-control text"
                              id="signup_home_address_zip_9"
                              name="signup[home_address_attributes][zip]"
                              type="text"
                              onChange={(event) => {
                                this.setState({ postcode: event.target.value });
                              }}
                            />
                          </div>
                        </div>
                        {this.state?.errorText?.postcode ? (
                          <p className="error-block">
                            {this.state?.errorText?.postcode}
                          </p>
                        ) : null}

                        <label>
                          Are you a member of the Conservative Party?*
                        </label>
                        <fieldset>
                          <div className="form-group labeled-tags-container">
                            <div className="form-group mb-1">
                              <div className="custom-control custom-radio">
                                <input
                                  type="radio"
                                  name="signup[labeled_tags][]"
                                  className="custom-control-input"
                                  id="checkbox_2_9_9"
                                  value="Yes"
                                  required=""
                                  onClick={(event) => {
                                    this.partyMemberSelection(
                                      event.target.checked,
                                      event.target.value
                                    );
                                  }}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="checkbox_2_9_9"
                                >
                                  Yes
                                </label>
                              </div>
                            </div>
                            <div className="form-group mb-1">
                              <div className="custom-control custom-radio">
                                <input
                                  type="radio"
                                  name="signup[labeled_tags][]"
                                  className="custom-control-input"
                                  id="checkbox_3_9_9"
                                  value="NO"
                                  onClick={(event) => {
                                    this.partyMemberSelection(
                                      event.target.checked,
                                      event.target.value
                                    );
                                  }}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="checkbox_3_9_9"
                                >
                                  No
                                </label>
                              </div>
                            </div>
                            {this.state?.errorText?.partyMember ? (
                              <p className="error-block">
                                {this.state?.errorText?.partyMember}
                              </p>
                            ) : null}
                          </div>
                        </fieldset>
                        <div className="form-group ">
                          <input
                            className="btn btn-primary btn-block submit-button join-btn"
                            type="submit"
                            name="commit"
                            value="JOIN NOW"
                          />
                          <div className="form-submit"></div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-sm-12">
                            <div className="custom-control custom-checkbox custom-control-inline">
                              <input
                                name="signup[email_opt_in]"
                                type="hidden"
                                value="0"
                              />

                              <input
                                className="custom-control-input"
                                id="signup_email_opt_in_9_9"
                                name="signup[email_opt_in]"
                                type="checkbox"
                                value={true}
                                required=""
                                onClick={(event) => {
                                  this.termConditionCheck(
                                    event.target.checked,
                                    event.target.value
                                  );
                                }}
                              />
                              <label
                                className="custom-control-label small text-small"
                                htmlFor="signup_email_opt_in_9_9"
                                style={{ fontSize: "9pt" }}
                              >
                                By clicking “Join Now” I agree to the Ready for
                                Rishi campaign using the information I provide
                                to keep me updated via email and telephone about
                                the campaigns and opportunities to get involved.
                                For more information see our{" "}
                                {/* <a href="/" onClick={(e)=>{e.preventDefault(); this.setState({privarcyPolicy:true})}}>Privacy Policy</a>. */}
                                <Link to="/privacy">Privacy Policy</Link>
                              </label>
                            </div>
                            {this.state?.errorText?.termAndCondition ? (
                              <p className="error-block tc">
                                {this.state.errorText?.termAndCondition}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div className="form-row align-items-center"></div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="mobile_form" className=" d-block d-md-none container-padding">
          <div className="container">
            <h4>JOIN OUR CAMPAIGN FOR THE LATEST INFORMATION AND UPDATES</h4>
            <div className="form mobileform" id="mobileform">
              <form
                id="join_page_new_signup_form"
                className="ajaxForm signup_form"
                method="POST"
                onSubmit={(e) => {
                  this.submitForm(e);
                }}
              //   action="/forms/signups"
              //   encType="multipart/form-data"
              >
                <input
                  name="authenticity_token"
                  type="hidden"
                  value="ZCFulLvDVhsuuB0wx7gyij9sHSWjsxFvC2lrw3xc9vM="
                />
                <input name="page_id" type="hidden" value="9" />
                <input
                  name="return_to"
                  type="hidden"
                  value="https://www.ready4rishi.com/"
                />
                <div
                  className="email_address_form"
                  style={{ display: "none" }}
                  aria-hidden="true"
                >
                  <p>
                    <label htmlFor="email_address">Optional email code</label>
                    <br />
                    <input
                      name="email_address"
                      type="text"
                      className="text"
                      id="email_address"
                      autoComplete="off"
                    />
                  </p>
                </div>
                <div className="form-errors"></div>
                <div className="form-row">
                  <div className="form-group col-sm-6">
                    <div className="form-control-material">
                      <label
                        htmlFor="signup_first_name"
                        className="floating-label"
                      >
                        First Name*
                      </label>
                      <input
                        required="required"
                        className="form-control text"
                        id="signup_first_name"
                        name="signup[first_name]"
                        type="text"
                        onChange={(event) => {
                          this.setState({ first_name: event.target.value });
                        }}
                      />
                    </div>
                  </div>
                  {this.state?.errorText?.first_name ? (
                    <p className="error-block">
                      {this.state?.errorText?.first_name}
                    </p>
                  ) : null}
                  <div className="form-group col-sm-6">
                    <div className="form-control-material">
                      <label
                        htmlFor="signup_last_name"
                        className="floating-label"
                      >
                        Last Name*
                      </label>
                      <input
                        required="required"
                        className="form-control text"
                        id="signup_last_name"
                        name="signup[last_name]"
                        type="text"
                        onChange={(event) => {
                          this.setState({ last_name: event.target.value });
                        }}
                      />
                    </div>
                  </div>
                  {this.state?.errorText?.last_name ? (
                    <p className="error-block">
                      {this.state?.errorText?.last_name}
                    </p>
                  ) : null}
                </div>
                <div className="form-row">
                  <div className="form-group col-sm-6">
                    <div className="form-control-material">
                      <label htmlFor="signup_email" className="floating-label">
                        Email*
                      </label>
                      <input
                        required="required"
                        className="form-control text"
                        id="signup_email"
                        name="signup[email]"
                        type="email"
                        onChange={(event) => {
                          this.setState({ email: event.target.value });
                        }}
                      />
                    </div>
                  </div>
                  {this.state?.errorText?.email ? (
                    <p className="error-block">
                      {this.state?.errorText?.email}
                    </p>
                  ) : null}

                  <div className="form-group col-sm-6">
                    <div className="form-control-material">
                      <label
                        htmlFor="signup_mobile_number"
                        className="floating-label"
                      >
                        Mobile phone*
                      </label>
                      <input
                        required="required"
                        className="form-control text"
                        id="signup_mobile_number"
                        name="signup[mobile_number]"
                        type="tel"
                        onChange={(event) => {
                          this.setState({ mobile: event.target.value });
                        }}
                      />
                    </div>
                  </div>
                  {this.state?.errorText?.mobile ? (
                    <p className="error-block">
                      {this.state?.errorText?.mobile}
                    </p>
                  ) : null}
                </div>
                <div className="form-group ">
                  <div className="form-control-material">
                    <label
                      htmlFor="signup_home_address_zip"
                      className="floating-label"
                    >
                      Postcode*
                    </label>
                    <input
                      required="required"
                      className="form-control text"
                      id="signup_home_address_zip"
                      name="signup[home_address_attributes][zip]"
                      type="text"
                      onChange={(event) => {
                        this.setState({ postcode: event.target.value });
                      }}
                    />
                  </div>
                </div>
                {this.state?.errorText?.postcode ? (
                  <p className="error-block">
                    {this.state?.errorText?.postcode}
                  </p>
                ) : null}

                <label>Are you a member of the Conservative Party?*</label>
                <fieldset>
                  <div className="form-group labeled-tags-container">
                    <div className="form-group mb-1">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          name="signup[labeled_tags][]"
                          className="custom-control-input"
                          id="checkbox_2_9"
                          value="YES"
                          onClick={(event) => {
                            this.partyMemberSelection(
                              event.target.checked,
                              event.target.value
                            );
                          }}
                          required=""
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="checkbox_2_9"
                        >
                          Yes
                        </label>
                      </div>
                    </div>


                    <div className="form-group mb-1">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          name="signup[labeled_tags][]"
                          className="custom-control-input"
                          id="checkbox_3_9"
                          value="NO"
                          onClick={(event) => {
                            this.partyMemberSelection(
                              event.target.checked,
                              event.target.value
                            );
                          }}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="checkbox_3_9"
                        >
                          No
                        </label>
                      </div>
                      {this.state?.errorText?.partyMember ? (
                        <p className="error-block">
                          {this.state?.errorText?.partyMember}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </fieldset>
                <div className="form-group ">
                  <input
                    className="btn btn-primary btn-block submit-button"
                    type="submit"
                    name="commit"
                    value="JOIN NOW"
                  />
                  <div className="form-submit"></div>
                </div>
                <div className="form-row">
                  <div className="form-group col-sm-12">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        name="signup[email_opt_in]"
                        type="hidden"
                        value="0"
                      />
                      <input
                        className="custom-control-input"
                        id="signup_email_opt_in_9"
                        name="signup[email_opt_in]"
                        type="checkbox"
                        value={true}
                        required=""
                        // onClick={(event) => {
                        //   event.target.checked
                        //     ? this.setState({ termConditionCheck: true })
                        //     : this.setState({ termConditionCheck: false });
                        // }}
                        onClick={(event) => {
                          this.termConditionCheck(
                            event.target.checked,
                            event.target.value
                          );
                        }}
                      />
                      <label
                        className="custom-control-label small text-small"
                        htmlFor="signup_email_opt_in_9"
                        style={{ fontSize: "9pt" }}
                      >
                        By clicking “Join Now” I agree to the Ready for Rishi
                        campaign using the information I provide to keep me
                        updated via email and telephone about the campaigns and
                        opportunities to get involved. For more information see
                        our
                        {/* <a href="/" onClick={(e)=>{e.preventDefault();this.setState({privarcyPolicy:true})}}>Privacy Policy</a>. */}
                        <Link to="/privacy"> &nbsp;Privacy Policy</Link>
                      </label>
                    </div>
                    {this.state?.errorText?.termAndCondition ? (
                      <p className="error-block">
                        {this.state?.errorText?.termAndCondition}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="form-row align-items-center"></div>
              </form>
            </div>
            <img
              src="https://assets.nationbuilder.com/themes/62c94d2245de9407636a2ba0/attachments/original/1657363215/divider.svg?1657363215"
              alt=""
            />
          </div>
        </section>
        <div className="yt-videos">
          {this.state.youtubeVideos.length > 0 ?
            this.state.youtubeVideos.map((item, index) =>
              <div className="yt-v">
                <iframe
                  key={index}
                  id={`video${index + 1}`} title={item.title} width={item.width} height={item.height} src={item.src} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen></iframe>
                {/* <ReactPlayer url={item.src}  width={item.width} height={item.height}/> */}
              </div>
            ) : null
          }


        </div>
        <section
          id="homepage"
          className="homepage"
          aria-label="Homepage content"
        >
          <script src="https://rs.nationbuilder.com/themes/2/62c94d2245de9407636a2ba0/0/attachments/16573777821657379742/mobile/adjust-offset.js"></script>
          <div id="flash_container"></div>
          <section
            id="about"
            className="homepage-section basic position-relative odd pt-6 pb-6"
            aria-label="About section"
          >
            <div className="container">

              <div className="row">
                <div className="col-11 col-lg-6 mx-auto">
                  <div id="intro" className="text-center intro">
                    <h3>
                      RESTORE TRUST, REBUILD THE ECONOMY AND REUNITE THE COUNTRY
                    </h3>
                    <p>
                      I got into politics because I want everyone in this
                      country to have the opportunity to be able to give their
                      children a better future.
                    </p>
                    <p>
                      Our country faces huge challenges, the most serious for a
                      generation. Someone has to grip this moment and make the
                      right decisions. Because the choices we make today will
                      decide whether the next generation of British people will
                      have more opportunities than the last.
                    </p>
                    <p>
                      We need to restore trust in our politics. We need to
                      rebuild our economy. And we need to reunite the country.
                    </p>
                    <p>
                      That’s why I’m standing to be the next leader of the
                      Conservative Party and your Prime Minister.
                    </p>
                    <p>
                      <img
                        src="https://assets.nationbuilder.com/rs/pages/14/attachments/original/1657469330/rishi_sig.png?1657469330"
                        alt=""
                        width="148"
                        height="59"
                      />
                      <br />
                      <br />
                    </p>
                    <p>
                      <span
                        id="second-join-now"
                        className="d-none d-md-inline-block btn btn-primary"
                        onClick={() => {
                          document.querySelector("body").scrollIntoView({ behavior: 'smooth' });
                        }}
                      >
                        JOIN NOW
                      </span>{" "}
                      <span
                        className="d-inline-block d-md-none btn btn-primary"
                        onClick={() => {
                          document.getElementById("mobile_form").scrollIntoView({ behavior: 'smooth' });

                        }}
                      >
                        JOIN NOW
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </div>
    );
  }
}

export default MainLayout;
