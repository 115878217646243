import React from "react";
import "./App.css";
import { Link } from "react-router-dom";
import HEROIMG from "./assets/hero_logo.png";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <footer className="footer text-center position-relative bg-primaryy">
          <div className="container">
            <div className="row">
              <div className="col-md-8 mx-auto">
                <div className="footer-content my-2">
                  <p>
                    <small>
                      Promoted by Lord Smith of Hindhead CBE on behalf of Rishi
                      Sunak MP, at 3 Dean Trench Street, SW1P 3HB, London.
                    </small>
                  </p>
                  <p>
                    {/* <a href="/" onClick={(e)=>{e.preventDefault(); this.setState({privarcyPolicy:true})}}>Privacy Policy</a> */}
                    <Link to="/privacy">Privacy Policy</Link>
                  </p>
                </div>
                <hr className="footer-ruler mt-5 mb-4" />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <img
                  // src="https://assets.nationbuilder.com/themes/62c6bf4fc294806c46e9a325/attachments/original/1657202390/rishi_logo.png?1657202390"
                  // src="https://assets.nationbuilder.com/rs/sites/2/meta_images/original/rishi_logo_blue.png?1657236873"
                  src={HEROIMG}
                  className="my-4 navbar-brand-image"
                  alt="Ready for Rishi"
                  onClick={() => {
                    this.props.history("/");
                    // window.location.reload()
                  }}
                />
                {/* 
                      <div className="footer-fineprint text-white">Created with <a
                         href="https://nationbuilder.com/?utm_source=footer&amp;utm_medium=referral&amp;utm_campaign=rs"
                         target="_blank" rel="noopener" className="text-muted">NationBuilder</a></div>
                      */}
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer;
