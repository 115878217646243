import React from "react";
import "./App.css";
// import { Link } from "react-router-dom";
// import Typewriter from "typewriter-effect";
import BG_PLANE from "./assets/bg_3.png";
// import BG_IMAGE from "./assets/rishi_img.webp";
import CLOSE from "./assets/menu_burger.png";
import HEROIMG from "./assets/hero_logo.png";
import FB from "./assets/facebook.png";
import TWITTER from "./assets/twitter.png";
import INSTAGRAM from "./assets/instagram.png";
import LINKEDIN from "./assets/linkedin.png";
import YOUTUBE from "./assets/youtube.png";
// import DOWN_ARROW from "./assets/down-arrow.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { base_url, signup_endpoint } from "./config";
import { Timeline } from 'react-twitter-widgets'
class TwitterFeeds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headerDynamicClass: "bg-transparent",
      header2DynamicClass: "",
      scrollPosition: 0,
      first_name: null,
      last_name: null,
      email: null,
      mobile: null,
      postcode: null,
      termAndCondition: null,
      partyMember: null,
      privarcyPolicy: false,
      afterSumitForm: false,
      shareModal: false,
      errorText: null,
      responsiveHeader: false,
      youtubeVideos: []
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    window.scrollTo(0, 0);
    this.setYoutubeVideosLinks()
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  setYoutubeVideosLinks = () => {
    let data = [
      { id: 1, title: 'v1', width: "424", height: "238", src: 'https://www.youtube.com/embed/Qxajk5N3dkY' },
      { id: 2, title: 'v2', width: "424", height: "238", src: 'https://www.youtube.com/embed/Qxajk5N3dkY' },
      { id: 3, title: 'v3', width: "424", height: "238", src: 'https://www.youtube.com/embed/Qxajk5N3dkY' },
    ]


    this.setState({ youtubeVideos: data })
  }

  handleScroll = () => {
    const position = window.pageYOffset;
    this.setState({ scrollPosition: position });
    if (position > 750) {
      this.setState({ header2DynamicClass: "bg-color-3" });
    } else {
      this.setState({ header2DynamicClass: "" });
    }
  };

  submitForm = (e) => {
    e.preventDefault();
    console.log("Clicked", this.state);
    //   this.setState({errorText:null});
    const {
      first_name,
      last_name,
      email,
      mobile,
      postcode,
      partyMember,
      termAndCondition,
    } = this.state;
    this.setState({ errorText: null });
    //checking for the null value
    if (!first_name) {
      return this.setState({
        errorText: { first_name: "Please enter First name" },
      });
    }
    if (!last_name) {
      return this.setState({
        errorText: { last_name: "Please enter Last name" },
      });
    }
    if (!mobile) {
      return this.setState({
        errorText: { mobile: "Please enter Mobile number" },
      });
    }
    if (!email) {
      return this.setState({ errorText: { email: "Please enter Email" } });
    }
    if (!postcode) {
      return this.setState({
        errorText: { postcode: "Please enter Postcode" },
      });
    }
    if (!partyMember) {
      return this.setState({
        errorText: {
          partyMember: "Please select  conservative party member option",
        },
      });
    }
    if (!termAndCondition) {
      return this.setState({
        errorText: { termAndCondition: "Please accept Terms and Conditions" },
      });
    }

    if (mobile) {
      if (mobile.length < 10) {
        return this.setState({
          errorText: { mobile: " Invalid Mobile number" },
        });
      }
    }
    console.log(this.state.errorText);

    this.submitApiCall();
  };

  submitApiCall() {
    let reqPayload = {
      first_name: this.state?.first_name,
      last_name: this.state?.last_name,
      phone_number: this.state?.mobile,
      email: this.state?.email,
      postcode: this.state?.postcode,
      member_of_conservative_party:
        this.state?.partyMember === "Yes" ? true : false,
    };
    console.log(this.state, reqPayload);
    let url = base_url + signup_endpoint;
    let fetchOptions = {
      method: "post",
      headers: { "Content-Type": "application/json", token: 12345 },
      body: JSON.stringify(reqPayload),
    };
    fetch(url, fetchOptions)
      .then((res) => res.json())
      .then((result) => {
        if (result?.errors) {
          toast.dark(result?.errors, {
            position: "bottom-center",
            autoClose: 5000,
            type: "error",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (result?.data) {
          localStorage.setItem("id", result?.data.id);
          this.props.history("/submit");
        }
      });
  }
  partyMemberSelection = (isChecked, value) => {
    //   console.log(isChecked,value);
    this.setState({ partyMember: value });
  };

  termConditionCheck = (isChecked, value) => {
    console.log(isChecked, value);
    if (isChecked) {
      this.setState({ termAndCondition: value });
    } else {
      this.setState({ termAndCondition: null });
    }
  };

  render() {
    // console.log(this.props)
    return (
      <div className="app-main">
        {/* <SideBar /> */}
        <ToastContainer />
        {this.state.responsiveHeader &&
          <div className="float-feeds-wrapper">
            <div className="float-feeds-container">

              <div className="flex_sb_center">

                <div className="sidebar_head">
                  Social Media Feeds
                </div>


                <span className="float-close-button" onClick={() => this.setState({ responsiveHeader: false })} >

                  <img src={CLOSE} height="20px" width="20px" alt="" />
                </span>

              </div>

              <div className="feed-buttons">
                <div className="topnav-btn twitter" onClick={() => { this.props.history('/twitter-feeds') }}>
                  <img src={TWITTER} height="20px" width="20px" alt="Twitter" />
                  Twitter Feed
                </div>
              <div className="topnav-btn linkedin" onClick={() => {
                // this.props.history('/linkedin-feeds')
                window.open("https://uk.linkedin.com/in/rishi-sunak-4361871a5")

              }}>
                  <img src={LINKEDIN} height="20px" width="20px" alt="Linkedin" />
                  Linkedin Feed</div>
              </div>

            </div>
          </div>
        }
        <div className="float-feeds">
          <div className="float-feeds-button linkedin">
            <div className="feed-flex" onClick={() => {
              // this.props.history('/linkedin-feeds')
                window.open("https://uk.linkedin.com/in/rishi-sunak-4361871a5")

            }}>
              <img style={{ marginTop: 4 }} src={LINKEDIN} height="20px" width="20px" alt="social-handel" />
              <span className="float-feeds-txt">Feed</span>
            </div>

          </div>
          <div className="float-feeds-button twitter">
            <div className="feed-flex" onClick={() => { this.props.history('/twitter-feeds') }}>
              <img src={TWITTER} height="30px" width="30px" alt="social-handel" />
              <span className="float-feeds-txt">Feed</span>
            </div>

          </div>
        </div>



        <section id="hero" className="hero" aria-label="Hero section">

          <header
            id="header"
            className={`navbar navbar-expand-md navbar-dark position-absolute p-4 text-light bg-color-3 ${this.state?.headerDynamicClass}`}
          >
            <div className="mx-auto container-fluid d-flex  row" >

              <div className="col-md-1"></div>
              <div
                className="navbar-brand-container flex-grow-1 py-2 px-1 px-md-3 p-md-0"
                style={{ alignSelf: "self-start" }}
              >
                <input id="menu-toggle" type="checkbox" onChange={() => { this.setState({ responsiveHeader: !this.state.responsiveHeader }) }} />
                <label className='menu-button-container' for="menu-toggle">
                  <div className='menu-button'></div>
                </label>


                {/* <div className=" navbar-brand-container flex-grow-1 py-2 px-1 px-md-3 p-md-0 text-center"> */}
                <h1 className="h3 mb-0 logo-center-responsive">
                  <a className="navbar-brand text-color-2" href="/">
                    <img
                      // src="https://assets.nationbuilder.com/themes/62c6bf4fc294806c46e9a325/attachments/original/1657202390/rishi_logo.png?1657202390"
                      src={HEROIMG}
                      className="mx-auto navbar-brand-image"
                      alt="Ready for Rishi"
                    />
                  </a>
                </h1>
              </div>
              <nav
                className="col-md-4 px-0 collapse navbar-collapse position-fixed nav-class"
                id="primaryNav"
                aria-label="Main navigation"
              >
                <ul
                  className={`navbar-nav flex-wrap ml-auto icon-custom ${this.state?.header2DynamicClass}`}
                  style={{ flexDirection: "column" }}
                >
                  <li className="nav-item">
                    <a
                      href="https://www.facebook.com/rishisunak"
                      className="nav-link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={FB} alt="not_found" className="social-icon" />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="https://twitter.com/RishiSunak"
                      className="nav-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={TWITTER}
                        alt="not_found"
                        className="social-icon"
                      />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="https://www.instagram.com/rishisunakmp/"
                      className="nav-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={INSTAGRAM}
                        alt="not_found"
                        className="social-icon"
                      />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="https://www.youtube.com/c/RishiSunakMP"
                      className="nav-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={YOUTUBE}
                        alt="not_found"
                        className="social-icon"
                      />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="https://www.linkedin.com/in/rishi-sunak-4361871a5/"
                      className="nav-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={LINKEDIN}
                        alt="not_found"
                        className="social-icon"
                      />
                    </a>
                  </li>
                </ul>
                <ul
                  id="navbar-toolbar"
                  className="navbar-toolbar list-unstyled d-flex align-items-center mt-2 mt-md-0 mb-2 mb-md-0 ml-3"
                ></ul>
              </nav>
            </div>
          </header>

          <div
            className="hero-container hero-homepage bg-color-3 d-flex align-items-center jumbotron jumbotron-fluid h-100  position-relative header-custom"
            style={{
              backgroundImage: 'url("' + BG_PLANE + '")',
              marginBottom: 0,
              minHeight: '80vh',
              backgroundSize: '100% 100%',
            }}
          >
            <div className="position-absolute"></div>
            {/* <div className="scroll-view">
              <img src={DOWN_ARROW} height="50px" width="50px" alt="Down" />
              <p>Scroll down for joining our campaign</p>
            </div> */}
            <div className="container web_container">
              <div className="tweet_feeds_div">
              <Timeline
  dataSource={{
    sourceType: 'widget',
    screenName: 'RishiSunak'
  }}
  options={{
    height: '500',
    width: '1000',
  }}
/>
              </div>
            </div>
          </div>
        </section>


        <section
          id="homepage"
          className="homepage"
          aria-label="Homepage content"
        >
          <script src="https://rs.nationbuilder.com/themes/2/62c94d2245de9407636a2ba0/0/attachments/16573777821657379742/mobile/adjust-offset.js"></script>
          <div id="flash_container"></div>

        </section>
      </div>
    );
  }
}

export default TwitterFeeds;
