import React from "react";
import "./App.css";
import HEROIMG from "./assets/hero_logo.png";
import HEROTHUMBSUP from "./assets/hero_thumbsup.png";
import FB from "./assets/facebook.svg";
// import TWITTER from "./assets/twitter.svg";
import TWITTER from "./assets/twitter.png";
import INSTAGRAM from "./assets/instagram.png";
import LINKEDIN from "./assets/linkedin.png";
import YOUTUBE from "./assets/youtube.png";
import Whatsapp from "./assets/whatsapp.svg";
import Email from "./assets/email.svg";
import LIKE1 from "./assets/like1.png";
import CLOSE from "./assets/menu_burger.png";
import { base_url, update_endpoint } from "./config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Submit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      getInvolvedData: [],
      errorText: null,
      shareModal: false,
      successResponse: false,
      responsiveHeader: false,

    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ shareModal: true });
  }

  saveInfo = () => {
    if (this.state?.getInvolvedData.length > 0) {
      this.submitApiCall();
    } else {
      this.setState({ errorText: "Please select atleast one" });
    }
  };

  submitApiCall() {
    let reqPayload = {
      involvement_type: this.state?.getInvolvedData,
    };
    let url = base_url + update_endpoint + localStorage.getItem("id");
    let fetchOptions = {
      method: "put",
      headers: { "Content-Type": "application/json", token: 12345 },
      body: JSON.stringify(reqPayload),
    };
    fetch(url, fetchOptions)
      .then((res) => res.json())
      .then((result) => {
        if (result?.errors) {
          toast.dark(result?.errors, {
            position: "bottom-center",
            autoClose: 5000,
            type: "error",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (result?.data) {
          this.setState({ successResponse: true });
        }
      });
  }
  addGetInvolvedData = (e) => {
    let value = e.target.value;
    let checked = e.target.checked;
    if (checked) {
      let array = this.state?.getInvolvedData;
      array.push(value);
      this.setState({ involvedData: array });
    } else {
      let array = this.state?.getInvolvedData;
      if (array.length > 0) {
        let p = array.filter((item) => {
          return item !== value;
        });
        this.setState({ getInvolvedData: p });
      }
    }
  };
  render() {
    return (
      <div className="app-main">
          {this.state.responsiveHeader &&
          <div className="float-feeds-wrapper">
            <div className="float-feeds-container">

              <div className="flex_sb_center">

                <div className="sidebar_head">
                  Social Media Feeds
                </div>


                <span className="float-close-button" onClick={() => this.setState({ responsiveHeader: false })} >
                  <img src={CLOSE} height="20px" width="20px" alt="" />

                </span>

              </div>

              <div className="feed-buttons">
                <div className="topnav-btn twitter" onClick={() => { this.props.history('/twitter-feeds') }}>
                  <img src={TWITTER} height="20px" width="20px" alt="Twitter" />
                  Twitter Feed
                </div>
                <div className="topnav-btn linkedin" onClick={() => {
                  // this.props.history('/linkedin-feeds')
                  window.open("https://uk.linkedin.com/in/rishi-sunak-4361871a5")

                }}>
                  <img src={LINKEDIN} height="20px" width="20px" alt="Linkedin" />
                  Linkedin Feed</div>
              </div>

            </div>
          </div>
        }
        <div className="float-feeds">
          <div className="float-feeds-button linkedin">
            <div className="feed-flex" onClick={() => {
              // this.props.history('/linkedin-feeds')
              window.open("https://uk.linkedin.com/in/rishi-sunak-4361871a5")
            }}>
              <img style={{ marginTop: 4 }} src={LINKEDIN} height="20px" width="20px" alt="social-handel" />
              <span className="float-feeds-txt">Feed</span>
            </div>

          </div>
          <div className="float-feeds-button twitter">
            <div className="feed-flex" onClick={() => { this.props.history('/twitter-feeds') }}>
              <img src={TWITTER} height="30px" width="30px" alt="social-handel" />
              <span className="float-feeds-txt">Feed</span>
            </div>

          </div>
        </div>



        <section id="hero" className="hero" aria-label="Hero section">

          <header
            id="header"
            className={`navbar navbar-expand-md navbar-dark position-absolute p-4 text-light bg-color-3 ${this.state?.headerDynamicClass}`}
          >
            <div className="mx-auto container-fluid d-flex  row" >

              <div className="col-md-1"></div>
              <div
                className="navbar-brand-container flex-grow-1 py-2 px-1 px-md-3 p-md-0"
                style={{ alignSelf: "self-start" }}
              >
                <input id="menu-toggle" type="checkbox" onChange={() => { this.setState({ responsiveHeader: !this.state.responsiveHeader }) }} />
                <label className='menu-button-container' for="menu-toggle">
                  <div className='menu-button'></div>
                </label>
                {/* <div className=" navbar-brand-container flex-grow-1 py-2 px-1 px-md-3 p-md-0 text-center"> */}
                <h1 className="h3 mb-0">
                  <a className="navbar-brand text-color-2" href="/">
                    <img
                      // src="https://assets.nationbuilder.com/themes/62c6bf4fc294806c46e9a325/attachments/original/1657202390/rishi_logo.png?1657202390"
                      src={HEROIMG}
                      className="mx-auto navbar-brand-image"
                      alt="Ready for Rishi"
                    />
                  </a>
                </h1>
              </div>
              <nav
                className="col-md-4 px-0 collapse navbar-collapse position-fixed nav-class"
                id="primaryNav"
                aria-label="Main navigation"
              >
                <ul
                  className={`navbar-nav flex-wrap ml-auto icon-custom ${this.state?.header2DynamicClass}`}
                  style={{ flexDirection: "column" }}
                >
                  <li className="nav-item">
                    <a
                      href="https://www.facebook.com/rishisunak"
                      className="nav-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={FB} alt="not_found" className="social-icon" />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="https://twitter.com/RishiSunak"
                      className="nav-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={TWITTER}
                        alt="not_found"
                        className="social-icon"
                      />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="https://www.instagram.com/rishisunakmp/"
                      className="nav-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={INSTAGRAM}
                        alt="not_found"
                        className="social-icon"
                      />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="https://www.youtube.com/c/RishiSunakMP"
                      className="nav-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={YOUTUBE}
                        alt="not_found"
                        className="social-icon"
                      />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="https://www.linkedin.com/in/rishi-sunak-4361871a5/"
                      className="nav-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={LINKEDIN}
                        alt="not_found"
                        className="social-icon"
                      />
                    </a>
                  </li>
                </ul>
                <ul
                  id="navbar-toolbar"
                  className="navbar-toolbar list-unstyled d-flex align-items-center mt-2 mt-md-0 mb-2 mb-md-0 ml-3"
                ></ul>
              </nav>
            </div>
          </header>

          <div
            className="hero-container hero-homepage bg-color-3 d-flex align-items-center jumbotron jumbotron-fluid h-100 mb-4 position-relative"
            style={{
              backgroundImage: 'url("' + HEROTHUMBSUP + '")',
              minHeight: "50vh",
              // borderBottom: "20px solid #00bcf0",
            }}
          >
            <div className="position-absolute"></div>
            <div
              className="container"
              style={{ marginTop: "80px", marginLeft: "12.5%" }}
            >
              <div id="main-content" className="hero-page-content mt-4">
                <div className="row">
                  <div className="col-12">
                    <span className="display-4 signup-text">
                      Thanks for <br />
                      Signing up !
                      <br />
                    </span>
                    <span className="How-would-you-like-t">
                      How would you like to get involved?
                    </span>
                    {this.state?.errorText !== null ? (
                      <span className="error-block-submit">{this.state.errorText}</span>
                    ) : null}
                    <div className="checkbox-group">
                      <div>
                        <input
                          type="checkbox"
                          className="get-involve-checkbox"
                          value={"Volunteering"}
                          onClick={(e) => {
                            this.addGetInvolvedData(e);
                          }}
                        />
                        <span className="get-involve-checkbox-label">
                          Volunteering
                        </span>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          className="get-involve-checkbox"
                          value={"Social Media"}
                          onClick={(e) => {
                            this.addGetInvolvedData(e);
                          }}
                        />
                        <span className="get-involve-checkbox-label">
                          Social Media
                        </span>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          className="get-involve-checkbox"
                          value={"I’m in the media"}
                          onClick={(e) => {
                            this.addGetInvolvedData(e);
                          }}
                        />
                        <span className="get-involve-checkbox-label">
                          I’m in the media
                        </span>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          className="get-involve-checkbox"
                          value={"Events"}
                          onClick={(e) => {
                            this.addGetInvolvedData(e);
                          }}
                        />
                        <span className="get-involve-checkbox-label">
                          Events
                        </span>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          className="get-involve-checkbox"
                          value={"I’m a Conservative Councillor"}
                          onClick={(e) => {
                            this.addGetInvolvedData(e);
                          }}
                        />
                        <span className="get-involve-checkbox-label">
                          I’m a Conservative Councillor
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="ssubmit-button"
                    onClick={() => {
                      this.saveInfo();
                    }}
                  >
                    <div className="SAVE-INFO">SAVE INFO</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="homepage"
          className="homepage"
          aria-label="Homepage content"
        >
          <script src="https://rs.nationbuilder.com/themes/2/62c94d2245de9407636a2ba0/0/attachments/16573777821657379742/mobile/adjust-offset.js"></script>
          <div id="flash_container"></div>
          <section
            id="about"
            className="homepage-section basic position-relative odd pt-6 pb-6"
            aria-label="About section"
          >
            <div className="container">
              <div className="row">
                <div className="col-11 col-lg-6 mx-auto">
                  <div id="intro" className="text-center intro">
                    <h3>
                      RESTORE TRUST, REBUILD THE ECONOMY AND REUNITE THE COUNTRY
                    </h3>
                    <p>
                      I got into politics because I want everyone in this
                      country to have the opportunity to be able to give their
                      children a better future.
                    </p>
                    <p>
                      Our country faces huge challenges, the most serious for a
                      generation. Someone has to grip this moment and make the
                      right decisions. Because the choices we make today will
                      decide whether the next generation of British people will
                      have more opportunities than the last.
                    </p>
                    <p>
                      We need to restore trust in our politics. We need to
                      rebuild our economy. And we need to reunite the country.
                    </p>
                    <p>
                      That’s why I’m standing to be the next leader of the
                      Conservative Party and your Prime Minister.
                    </p>
                    <p>
                      <img
                        src="https://assets.nationbuilder.com/rs/pages/14/attachments/original/1657469330/rishi_sig.png?1657469330"
                        alt=""
                        width="148"
                        height="59"
                      />
                      <br />
                      <br />
                    </p>
                    <p>
                      <a
                        id="second-join-now"
                        className="d-none d-md-inline-block btn btn-primary"
                        href="#main-content"
                      >
                        JOIN NOW
                      </a>
                      <a
                        className="d-inline-block d-md-none btn btn-primary"
                        href="#mobileform"
                      >
                        JOIN NOW
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>

        {this.state?.shareModal ? (
          <div className="share-dialog-wrapper">
            <div>
              <div className="dialog-image-container">
                <span
                  id="close"
                  onClick={() => {
                    this.setState({ shareModal: false });
                  }}
                >
                  +
                </span>
                <section className="dialog-content">
                  <p className="dialog-head">Welcome !</p>
                  <p className="dialog-text">
                    Please share with your friends to help us build the
                    campaign...
                  </p>
                  <div
                    className="share-dialog-button"
                    style={{ background: "#2D9ECB" }}
                  >
                    <img src={Email} alt="email" />
                    <div>
                      <a
                        style={{ textDecoration: "none", color: "#fff" }}
                        href="mailto:?subject=&body=https://ready4web.azureedge.net/"
                      >
                        Email
                      </a>
                    </div>
                  </div>
                  <div
                    className="share-dialog-button"
                    style={{ background: "#3B5999" }}
                  >
                    <img src={FB} alt="" />
                    <div>
                      <a
                        style={{ textDecoration: "none", color: "#fff" }}
                        href="https://www.facebook.com/sharer/sharer.php?u=https://ready4web.azureedge.net/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Facebook
                      </a>
                    </div>
                  </div>
                  <div
                    className="share-dialog-button"
                    style={{ background: "#0094bd" }}
                  >
                    <img src={TWITTER} alt="" />
                    <div>
                      <a
                        style={{ textDecoration: "none", color: "#fff" }}
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://twitter.com/share?url=https://ready4web.azureedge.net/"
                      >
                        Twitter
                      </a>
                    </div>
                  </div>
                  <div
                    className="share-dialog-button"
                    style={{ background: "#35D367" }}
                  >
                    <img src={Whatsapp} alt="" />
                    <div>
                      <a
                        href="https://wa.me?text='https://ready4web.azureedge.net/'"
                        style={{ textDecoration: "none", color: "#fff" }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Whatsapp
                      </a>
                    </div>
                  </div>

                  <span
                    onClick={() => {
                      this.setState({ shareModal: false });
                    }}
                    style={{
                      textDecoration: "underline",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    No Thanks
                  </span>
                </section>
              </div>
            </div>
          </div>
        ) : null}

        {this.state?.successResponse ? (
          <div className="share-dialog-wrapper">
            <div className="success-dialog-container">
              <div className="success-dialog-content">
                <img src={LIKE1} alt="icon" height="60" width="60" />
                <span style={{ color: "#fff", fontWeight: 700 }}>
                  Thank you. Someone will be in touch with you soon.
                </span>
              </div>
              <div className="success-dialog-btn">
                <input
                  className="btn btn-primary  submit-button join-btn"
                  style={{ height: "40px", cursor: "pointer" }}
                  //  name="commit"
                  value="OKAY"
                  onClick={() => {
                    this.props.history("/");
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Submit;
