import "./App.css";
import React from "react";
import { Routes, Route } from "react-router-dom";
import Footer from "./Footer";
import MainLayout from "./MainLayout";
import Privarcy from "./privarcyPolicy";
import Submit from "./Submit";
import TwitterFeeds from "./twitterFeeds";
import Feed from "./linkedinFeeds";

function App(props) {
  return (
    <>
      <div className="container">
        <Routes>
          <Route path="/" element={<MainLayout history={props.history} />} />
          <Route
            path="/privacy"
            element={<Privarcy history={props.history} />}
          />
          <Route path="/submit" element={<Submit history={props.history} />} />
          <Route path="/twitter-feeds" element={<TwitterFeeds history={props.history} />} />
          <Route path="/linkedin-feeds" element={<Feed history={props.history} />} />
        </Routes>
      </div>
      <Footer history={props.history} />
    </>
  );
}
export default App;
