import React from "react";
import "./App.css";
import HEROIMG from "./assets/hero_logo.png";
import LIKE from "./assets/like.png";
import SHARE from "./assets/share.png";
import TWEET from "./assets/tweet.png";
// import HERO_Privacy from "./assets/hero_privacy.png";
// import BG_IMAGE from "./assets/rishi.png";
// import FB from "./assets/facebook.png";
// import TWITTER from "./assets/twitter.png";
// import INSTAGRAM from "./assets/instagram.png";
// import LINKEDIN from "./assets/linkedin.png";
// import YOUTUBE from "./assets/youtube.png";
// import Typewriter from "typewriter-effect";

class Privacy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <>
        <section id="hero" className="hero" aria-label="Hero section">
          <header
            id="header"
            className={`navbar navbar-expand-md navbar-dark position-absolute p-4 text-light bg-color-3 ${this.state?.headerDynamicClass}`}
          >
            <div className="mx-auto container-fluid d-flex  row">
              <div className="col-md-1"></div>
              <div
                className=" navbar-brand-container flex-grow-1 py-2 px-1 px-md-3 p-md-0"
                style={{ alignSelf: "self-start" }}
              >
                {/* <div className=" navbar-brand-container flex-grow-1 py-2 px-1 px-md-3 p-md-0 text-center"> */}
                <h1 className="h3 mb-0">
                  <a className="navbar-brand text-color-2" href="/">
                    <img
                      // src="https://assets.nationbuilder.com/themes/62c6bf4fc294806c46e9a325/attachments/original/1657202390/rishi_logo.png?1657202390"
                      src={HEROIMG}
                      className="mx-auto navbar-brand-image"
                      alt="Ready for Rishi"
                    />
                  </a>
                </h1>
              </div>
              {/* <nav
                className="col-md-4 px-0 collapse navbar-collapse position-fixed nav-class"
                id="primaryNav"
                aria-label="Main navigation"
              >
                <ul
                  className={`navbar-nav flex-wrap ml-auto icon-custom ${this.state?.header2DynamicClass}`}
                  style={{ flexDirection: "column" }}
                >
                  <li className="nav-item">
                    <a
                      href="https://www.facebook.com/rishisunak"
                      className="nav-link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={FB} alt="not_found" className="social-icon" />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="https://twitter.com/RishiSunak"
                      className="nav-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={TWITTER} alt="not_found" className="social-icon" />

                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="https://www.instagram.com/rishisunakmp/"
                      className="nav-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={INSTAGRAM} alt="not_found" className="social-icon" />

                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="https://www.youtube.com/c/RishiSunakMP"
                      className="nav-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={YOUTUBE} alt="not_found" className="social-icon" />

                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="https://www.linkedin.com/in/rishi-sunak-4361871a5/"
                      className="nav-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={LINKEDIN} alt="not_found" className="social-icon" />

                    </a>
                  </li>
                </ul>
                <ul
                  id="navbar-toolbar"
                  className="navbar-toolbar list-unstyled d-flex align-items-center mt-2 mt-md-0 mb-2 mb-md-0 ml-3"
                ></ul>
              </nav> */}
            </div>
          </header>

          <div
            className="hero-container hero-homepage bg-color-3 d-flex align-items-center jumbotron jumbotron-fluid h-100 mb-4 position-relative header-content privacy-header-responsive"
          // style={{
          //   backgroundImage: 'url("' + HERO_Privacy + '")',
          // }}
          >
            <div className="position-absolute"></div>
            <div
              className="container web_container"
            >
              <div id="main-content" className="hero-page-content mt-4">
                <div className="row">
                  <div className="col-12">
                    <h1
                      style={{ lineHeight: 1 }}
                      className="display-4 text-uppercase"
                    >
                      PRIVACY
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="container">
          <div className="row">
            <div className="col-11 col-lg-6 mx-auto">
              <div id="intro" className="privarcy-text">
                This policy sets out the basis on which any personal data we
                (“Ready4Rishi/R4R") process personal data that you provide to
                us. Please read the following carefully to understand our views
                and practices regarding your personal data and how we will treat
                it.
                <br />
                <br />
                R4R is committed to ensuring the privacy and compliance with
                Data Protection law. It is important that you read this Privacy
                Policy carefully together with our Terms to understand our
                policies and practices regarding your Personal Data (as defined
                below) and how we will treat it.
                <br />
                <br />
                We operate as forum whereby you choose to sign up to receive
                information from us, and in doing so must consent to our policy,
                and our terms.
                <br />
                By interacting with our Website or R4R in other communications
                you must be willing to be bound by the data practices described
                in this Privacy Policy. If you do not agree with any part of
                this Privacy Policy, then please stop accessing our website, and
                do not communicate with us.
                <br />
                <br />
                This Privacy Policy explains how we collect and use your
                Personal Data in accordance with our obligations under
                applicable privacy and data protection law, including the
                retained EU law version of the General Data Protection
                Regulation ((EU) 2016/679) (UK GDPR), the Data Protection Act
                2018, and the Privacy and Electronic Communications Directive
                2002/58/EC (as updated by Directive 2009/136/EC) and the Privacy
                and Electronic Communications Regulations 2003 (SI 2003/2426) as
                amended.
                <br />
                <br />

                For the purposes of this Privacy Policy, the term ‘Personal
                Data’ means any information, which identifies you, or which
                allows you to be identified when combined with other
                information. It does not include data where your identity has
                been removed (‘Anonymised Data’).
                <br />
                <br />
                INFORMATION WE COLLECT AND HOW
                <br />
                <br />
                When you use our Website or communicate with us, including
                attending any event we organise, we may collect certain Personal
                Data that can be used to identify you. Any data that does not
                enable you to be identified will not be considered Personal
                Data.
                <br />
                <br />
                We may collect information and Personal Data from you:
                <br />
                <br />
                Via your interaction with our Website;
                <br />
                Through social media engagement including responses to online
                advertisement;
                <br />
                By email;
                <br />
                Over the phone; or
                <br />
                In person (e.g. paper based records).
                <br />
                We may collect and process the following data about you:
                <br />
                <br />
                Personal Data that we require in order to keep you informed of
                our work and activity will include: First name, Last name,
                Gender, Date of birth, Email or Physical Address, Telephone
                numbers and social media account information. Other information
                may be gathered depending on the circumstances.
                <br />
                <br />
                We may also ask you to complete surveys that we use for research
                purposes, although you do not have to respond to them.
                <br />
                <br />
                Device Information
                <br />
                <br />
                When you use our Website, we automatically collect information
                on the type of device you use, operating system, resolution,
                application version, mobile device identifiers (such as your
                device ID, advertising ID), language, time zone and IP address.
                <br />
                <br />
                Information we obtain from third parties
                <br />
                <br />
                We may receive information about you from our third party
                service providers (such as Google Analytics or social media
                platforms like Facebook), who collect this information through
                our social media or from interaction with our Websites in
                accordance with their own privacy policies.
                <br />
                <br />
                Special category data
                <br />
                <br />
                The information you may provide to us may include personal data
                revealing racial or ethnic origin, political opinions and other
                special categories of data. Such categories of data may be
                considered Special Categories of Personal Data for the purposes
                of the Applicable Data Protection Law unless they are adequately
                anonymised. We shall always process such personal data with
                suitable care and in accordance with the data protection law.
                <br />
                <br />
                Use of Personal Data
                <br />
                <br />
                We may use information about you for various purposes, including
                to: Provide, maintain and improve our website and social media
                content, and to communication with you which will include
                sending you newsletters and other information on our activity or
                political content that we think will be of interest to you.
                <br />
                <br />
                Social Sharing Features
                <br />
                <br />
                Our online content may offer social sharing features and other
                integrated tools (such as the Facebook “Like” button), which let
                you share actions you take on our Sites with other media, and
                vice versa. The use of such features enables the sharing of
                information with your friends or the public, depending on the
                settings you establish with the entity that provides the social
                sharing feature. For more information about the purpose and
                scope of data collection and processing in connection with
                social sharing features, please visit the privacy policies of
                the entities that provide these features.
                <br />
                <br />
                Aggregated Anonymised Data
                <br />
                <br />
                The information we collect from you may be combined with
                information provided by others, but only in an anonymised
                format, to produce aggregated anonymised data sets for research
                purposes. We refer to this combined data as ‘Aggregated Data.’
                Aggregated Data is not considered to be Personal Data as it does
                not reveal your identity.
                <br />
                <br />
                However, in the event that we combine or connect Aggregated Data
                with any of your Personal Data that enables you to be directly
                or indirectly identified, we will treat such data as Personal
                Data to be used in accordance with this Privacy Policy.
                <br />
                <br />
                USE OF COOKIES AND GOOGLE ANALYTICS
                <br />
                <br />
                R4R’s Website may use ‘cookies’ and similar technologies to
                enhance the users’ experience. You can set your browser to
                refuse all or some browser cookies, or to alert you when
                websites set or access cookies. If you disable or refuse
                cookies, please note that some parts of our website may become
                inaccessible or not function properly.
                <br />
                <br />
                LEGAL BASIS FOR USING YOUR PERSONAL DATA
                <br />
                <br />
                The legal bases we rely upon to use your Personal Data may
                include the [Terms] we have with you, your consent and our
                legitimate interests, or where we need to comply with a legal or
                regulatory obligation. Where processing special category data
                this will be by consent or the contract you have with us. Please
                contact us if you require further details concerning the
                specific legal ground(s) we are relying on to process your
                Personal Data.
                <br />
                <br />
                We offer here non-exhaustive examples of the ways in which we
                use your Personal Data and the legal bases we may rely upon to
                do so:
                <br />
                <br />
                If you are a participant completing one of our surveys, our
                legal basis for processing is your consent in taking part.
                <br />
                <br />
                To administer our Website our legal basis for processing is
                legitimate interests for running R4R.
                <br />
                We may use your Personal Data if you apply for employment with
                us, processed under consent and then under contract if you
                become employed by us.
                <br />
                <br />
                DATA SECURITY
                <br />
                <br />
                We have put in place appropriate security measures to prevent
                your Personal Data from being accidentally lost, used or
                accessed in an unauthorised way, altered or disclosed.
                <br />
                We limit access to your Personal Data to those employees,
                agents, contractors and other third parties who have a business
                need to process it.
                <br />
                <br />
                DATA RETENTION
                <br />
                <br />
                Your Personal Data will likely be retained for as long as you
                remain subscribed to R4R. Any Personal Data deemed no longer
                relevant is deleted. Though we will retain and use your Personal
                Data to the extent necessary to comply with our legal
                obligations (for example, if we are required to retain your data
                to comply with applicable laws), resolve disputes, and enforce
                our legal agreements and policies.
                <br />
                If we have taken steps to anonymise your personal data (so that
                it can no longer be associated with you) we may use this
                indefinitely for analytical, research and statistical purposes.
                <br />
                <br />
                YOUR RIGHTS
                <br />
                <br />
                Your right to withdraw consent and cease to be a subscriber at
                any time. Whenever we rely on your consent to process your
                Personal Data, you have the right to withdraw your consent at
                any time. If you wish to withdraw your consent, please contact
                R4R using the contact details provided at the end of this
                privacy policy. This will not affect the lawfulness of any
                processing carried out before you withdraw, nor ongoing
                contractual or other obligations requiring us to process data
                for example due to a court ordered law enforcement request.
                <br />
                <br />
                The UK GDPR provides the following rights for individuals:
                <br />
                <br />
                The right to be informed – is met by this policy and the
                information provided when you have contact with us.
                <br />
                The right to rectification – You have the right to ask us to
                rectify Personal Data you think is inaccurate. You also have the
                right to ask us to complete information you think is incomplete.
                <br />
                The right to erasure – You have the right to ask us to erase
                your Personal Data in certain circumstances.
                <br />
                The right to restrict processing – You have the right to ask us
                to restrict the processing of your Personal Data in certain
                circumstances.
                <br />
                The right to data portability – You have the right to ask that
                we transfer your Personal Data to another organisation, or to
                you, in certain circumstances.
                <br />
                The right to object – You have the right to object to the
                processing of your Personal Data in certain circumstances.
                <br />
                Your right to access the Personal Data we hold about you
                <br />
                You have the right to make a request to access your Personal
                Data (known as a “Data Subject Access Request” or “SAR”). We aim
                to respond electronically to all SARs within 28 days. We may
                require you to prove your identify before we reply to ensure we
                do not give out personal data to the wrong person. In
                circumstances where it may take us longer to respond (for
                example if your request is particularly complex or if you have
                made a series of requests), we will notify you. We do not charge
                a fee for responding to a SAR. However, we may charge a
                reasonable fee if your SAR is manifestly unfounded or excessive.
                <br />
                <br />
                <br />
                CHANGES TO THIS PRIVACY POLICY
                <br />
                <br />
                We may occasionally update this Privacy Policy. We encourage you
                to periodically review this Privacy Policy to be informed of how
                we are protecting your information.
                <br />
                <br />
                OPT-OUT & UNSUBSCRIBE
                <br />
                <br />
                We respect your privacy and give you an opportunity to opt-out
                of receiving communications. Users may opt-out of receiving any
                or all communications from us by contacting us or selecting the
                ‘Unsubscribe’ option on their email.
                <br />
                CONTACT INFORMATION
                <br />
                <br />
                If you have a question please contact us via our website.
                <br />
                <br />
                Date last updated: 8 July 2022
                <br />
                <br />
              </div>
              <div>
                <span className="Do-you-like-this-pag">
                  Do you like this page?
                  <br />
                  <br />
                </span>
                <div className="footer-privarcy">
                  <div className="like"> <a
                    style={{ textDecoration: "none", color: "#fff" }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://twitter.com/share?url=https://ready4web.azureedge.net/"
                  >
                    <img src={LIKE} alt="" /></a>
                  </div>
                  <div className="share">
                    <a
                      style={{ textDecoration: "none", color: "#fff" }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://twitter.com/share?url=https://ready4web.azureedge.net/privacy"
                    >
                      <img src={SHARE} alt="" /></a>
                  </div>
                  <span className="Sign-up-to-text">
                    <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">Sign up</a> to see what your friends like.
                  </span>
                  <div className="tweet">
                    <a
                      style={{ textDecoration: "none", color: "#fff" }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://twitter.com/share?url=https://ready4web.azureedge.net/"
                    >
                      <img src={TWEET} alt="" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Privacy;
